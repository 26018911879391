import styles from './glp-logistic-report.module.scss';
import classNames from 'classnames';
import { formatterDate, getQueryParams, viewInArrayViews } from '../../utils/functions';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState, useEffect, useContext, useMemo } from 'react';
import { HorizontalBarEChart } from '../horizontal-bar-e-chart/horizontal-bar-e-chart';
import { Column, GLPSales, GroupDataWithTypesProps } from '../../utils/interfaces';
import {
    createSendAlertEmail,
    groupData,
    postActionLogs,
    queryV1,
} from '../../services/python-services';
import { queries_glp, queries_glp_logistic, size_page } from '../../utils/constants';
import { PortMap } from '../port-map/port-map';
import { Table } from '../table/table';
import { PieEChart } from '../pie-e-chart/pie-e-chart';
import { FilterButton } from '../filter-button/filter-button';
import { FilterCard } from '../filter-card/filter-card';
import { Spinner } from 'flowbite-react';
import { RMContext } from '../../context/RMContext';
import Notiflix from 'notiflix';
import { useParams } from 'react-router-dom';

export interface GlpLogisticReportProps {
    name: string;
}

export const GlpLogisticReport = ({ name }: GlpLogisticReportProps) => {
    const cntr = window.location.search.split('?cntr=');
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 90));
    const [sortBy, setSortBy] = useState('');
    const [leadTimeShippingCompanyData, setLeadTimeShippingCompanyData] = useState([]);
    const [loadLeadTimeShippingCompanyData, setLoadLeadTimeShippingCompanyData] = useState(false);
    const [leadTimeDischargingPortData, setLeadTimeDischargingPortData] = useState([]);
    const [loadLeadTimeDischargingPortData, setLoadLeadTimeDischargingPortData] = useState(false);
    const [leadTimeTransportModeData, setLeadTimeTransportModeData] = useState([]);
    const [loadLeadTimeTransportModeData, setLoadLeadTimeTransportModeData] = useState(false);
    const [leadTimeCountryData, setLeadTimeCountryData] = useState([]);
    const [loadLeadTimeCountryData, setLoadLeadTimeCountryData] = useState(false);
    const [leadTimePortData, setLeadTimePortData] = useState([]);
    const initialTableState: any[] = [];
    const [mainTableData, setMainTableData] = useState(initialTableState);
    const [totalDatas, setTotalDatas] = useState(0);

    const [loadingMainData, setLoadingMainData] = useState(false);
    const [downLoader, setDownLoader] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [currentRow, setCurrentRow] = useState({ id: -1, type: '' });

    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(true);
    // FILTERS
    const [searchText, setSearchText] = useState('');
    const [applySearch, setApplySearch] = useState(true);
    const [customFilters, setCustomFilters] = useState<any>([]);
    const [executeApply, setExecuteApply] = useState(true);
    const filtersString = [
        'page',
        'size',
        'factory',
        'product-group-glp',
        'customer-glp',
        'country-glp',
        'delayed',
        'arrived',
        'finalctry',
        'gbm_name',
    ];
    const [closedFilters, setClosedFilters] = useState(true);
    const { finalctry }: any = getQueryParams(window.location.search);

    // console.log("finalctry: ",finalctry.toUpperCase() )
    const [queries, setQueries] = useState<GLPSales>({
        page: 1,
        size: size_page,
        sitefromname: null,
        divisionname: null,
        gbm_name: null,
        soldtoname: null,
        finalctry: finalctry !== undefined ? finalctry.toUpperCase() : null,
        'delayeddays:not': null,
        delayeddays: null,
        searchGlp: null,
        sort_by: null,
        delayed: null,
        cntr_truckno: cntr.length > 1 ? '' + cntr[1] : null,
    });

    const [uniques, setUniques] = useState<GLPSales>({
        sitefromname: null,
        divisionname: null,
        gbm_name: null,
        soldtoname: null,
        finalctry: finalctry !== undefined ? finalctry.toUpperCase() : null,
    });

    const clearValues = () => {
        setMainTableData([]);
        setTotalDatas(0);
        setPage(1);
    };
    const currentView = window.location.href.split('/').slice(-1)[0];
    const { sitemap } = useContext(RMContext).userData;

    useEffect(() => {
        const viewD = viewInArrayViews(currentView, sitemap);
        if (viewD?.hasOwnProperty('id')) {
            postActionLogs(viewD.id);
        }
    }, [sitemap]);

    const resetFilters = (nameComponent: string = 'filter') => {
        //clearValues();
        if (nameComponent === 'table') {
            setClosedFilters(true);
        }
        const updatedQueries = {
            page: 1,
            size: size_page,
            sitefromname: null,
            divisionname: null,
            gbm_name: null,
            soldtoname: null,
            finalctry: finalctry !== undefined ? finalctry.toUpperCase() : null,
            'delayeddays:not': null,
            delayeddays: null,
            searchGlp: null,
            sort_by: null,
            delayed: null,
        };
        setSearchText('');
        setPage(1);
        setQueries(updatedQueries);
        setApplySearch(true);
        setExecuteApply(true);
    };

    const applyFilters = (filters: any, type: string = '', apply: boolean = false) => {
        if (type === 'reset') {
            resetFilters();
        } else {
            //clearValues();
            setPage(1);
            const updatedQueries = {
                ...filters,
                page: 1,
                size: size_page,
                sort_by: sortBy !== '' ? sortBy : null,
            };
            setQueries(updatedQueries);
            setUniques(updatedQueries);
        }
        setExecuteApply(apply);
        setApplySearch(apply);
    };

    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };

    const getLeadTimeShippingCompanyData = async () => {
        setLoadLeadTimeShippingCompanyData(true);
        const response = await queryV1(
            queries_glp_logistic.leadTimeShippingCompany.table,
            queries_glp_logistic.leadTimeShippingCompany.groupsBy,
            queries_glp_logistic.leadTimeShippingCompany.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(a.avg_lead_time) - parseInt(b.avg_lead_time)
            );
            setLeadTimeShippingCompanyData(sort_data);
        }
        setLoadLeadTimeShippingCompanyData(false);
    };

    const getLeadTimeDischargingPortData = async () => {
        setLoadLeadTimeDischargingPortData(true);
        const response = await queryV1(
            queries_glp_logistic.leadTimeDischargingPort.table,
            queries_glp_logistic.leadTimeDischargingPort.groupsBy,
            queries_glp_logistic.leadTimeDischargingPort.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(a.avg_lead_time) - parseInt(b.avg_lead_time)
            );
            setLeadTimeDischargingPortData(sort_data);
        }
        setLoadLeadTimeDischargingPortData(false);
    };

    const getLeadTimeTransportModeData = async () => {
        setLoadLeadTimeTransportModeData(true);
        let response = await queryV1(
            queries_glp_logistic.leadTimeTransportMode.table,
            queries_glp_logistic.leadTimeTransportMode.groupsBy,
            queries_glp_logistic.leadTimeTransportMode.query,
            queries,
            'athena'
        );
        if (response) {
            let index_to_remove = -1;
            const valuesToSum = response?.data.filter((data: any, index: number) => {
                if (data.shiptype === 'AIR') {
                    index_to_remove = index;
                    return data;
                }
            });
            if (response?.data.length > 0 && valuesToSum.length > 0) {
                response.data[0].avg_lead_time =
                    '' +
                    (parseFloat(response.data[0].avg_lead_time) +
                        parseFloat(valuesToSum[0]?.avg_lead_time));
            }
            if (index_to_remove !== -1) response.data.splice(index_to_remove, 1);
            setLeadTimeTransportModeData(response.data);
        }
        setLoadLeadTimeTransportModeData(false);
    };

    const getLeadTimeCountryData = async () => {
        setLoadLeadTimeCountryData(true);
        const response = await queryV1(
            queries_glp_logistic.leadTimeCountry.table,
            queries_glp_logistic.leadTimeCountry.groupsBy,
            queries_glp_logistic.leadTimeCountry.query,
            queries,
            'athena'
        );
        if (response) {
            const sort_data = response?.data.sort(
                (a: any, b: any) => parseInt(a.avg_lead_time) - parseInt(b.avg_lead_time)
            );
            setLeadTimeCountryData(sort_data);
        }
        setLoadLeadTimeCountryData(false);
    };

    const getLeadTimePortData = async () => {
        const response = await queryV1(
            queries_glp_logistic.leadTimePort.table,
            queries_glp_logistic.leadTimePort.groupsBy,
            queries_glp_logistic.leadTimePort.query,
            queries,
            'athena'
        );
        if (response) {
            setLeadTimePortData(response.data.filter((q: any) => q.port !== null && q.port !== ''));
        }
    };
    const getAllMainData = async () => {
        let newQueiries = {
            ...queries,
            page: 1,
            size: size_page,
            sort_by: sortBy !== '' ? sortBy : 'arrived',
        };
        const response = await queryV1(
            queries_glp.mainTable.table,
            queries_glp.mainTable.groupsBy,
            queries_glp.mainTable.query,
            newQueiries,
            'athena'
        );
        if (response && response.data) {
            const newData = response.data?.map((row: any, index: number) => {
                return { ...row, index: index };
            });
            return newData;
        }
        return [];
    };
    const getMainTableData = async () => {
        setLoadingMainData(true);
        // let newQueiries = {
        //     ...queries,
        //     page: 1,
        //     size: size_page,
        //     sort_by: sortBy !== '' ? sortBy : 'arrived',
        // };
        // const response = await queryV1(
        //     queries_glp.mainTable.table,
        //     queries_glp.mainTable.groupsBy,
        //     queries_glp.mainTable.query,
        //     newQueiries,
        //     'athena'
        // );
        // if (response && response.data) {
        //add position in each row
        // const newData = response.data?.map((row: any, index: number) => {
        //     return { ...row, index: index };
        // });
        setMainTableData(await getAllMainData());
        //}
        setLoadingMainData(false);
    };
    const getTotalDatas = async () => {
        let filter_queries = { ...queries };
        filter_queries.sort_by = '';
        delete filter_queries.page;
        delete filter_queries.size;
        const response = await queryV1(
            queries_glp.totalMainTable.table,
            queries_glp.totalMainTable.groupsBy,
            queries_glp.totalMainTable.query,
            filter_queries,
            'athena'
        );
        if (response) {
            setTotalDatas(response?.data[0].totals);
            if (parseInt(response?.data[0].totals) <= mainTableData.length) {
                setMoreItems(false);
            } else {
                setMoreItems(true);
            }
        }
    };

    useEffect(() => {
        if (executeApply) {
            getLeadTimeShippingCompanyData();
            getLeadTimeDischargingPortData();
            getLeadTimeTransportModeData();
            getLeadTimeCountryData();
            getLeadTimePortData();
            setExecuteApply(false);
        }
        if (applySearch) {
            getTotalDatas();
            getMainTableData();
            setApplySearch(false);
        }
        setDataLoaded(true);
    }, [queries]);

    const convertDataToChart = (datas: any[]) => {
        return datas.map((data: object) => {
            return {
                label: Object.values(data)[0],
                value: parseFloat(parseFloat(Object.values(data)[1]).toFixed(2)),
            };
        });
    };

    const convertDataToMap = (datas: any[]) => {
        return datas.map((data: any) => {
            return {
                port: data['discharging__port'],
                value: parseFloat(data['avg_lead_time']).toFixed(2),
                lat: parseFloat(data['latitude']),
                lng: parseFloat(data['longitude']),
            };
        });
    };

    const sendAlert = async (dataR: any) => {
        //console.log("sendAlert", dataR);
        const alert = dataR.split('$');

        const obj = {
            po_sku: alert[0],
            cntr_truckno: alert[1],
            type: alert[2],
        };
        // console.log("customer", obj);
        //call the service to send the alert by email.
        const response = await createSendAlertEmail(obj);
        // console.log('response', response);
        if (response.message) {
            Notiflix.Notify.success(response?.message + ': ' + response?.emails);
        }
    };
    const getAlertFieldByType = (row: any, type: string) => {
        return (
            row.po_sku +
            '$' +
            row.cntr_truckno +
            '$' +
            type +
            '$' +
            row.soldtoname +
            '$' +
            row.shiptocode +
            '$' +
            (type === 'Pre Alert Notice'
                ? row.notice_date_pre_alert === null
                    ? 'show'
                    : 'hide'
                : type === 'Delay Notice'
                ? row.notice_date_delay === null
                    ? 'show'
                    : 'hide'
                : type === 'Arrival Notice'
                ? row.notice_date_arrival === null
                    ? 'show'
                    : 'hide'
                : '')
        );
    };

    const mainColumns: Column[] = [
        {
            id: 'arrived',
            name: 'Arrived',
            prop: 'arrived',
            open: false,
            show: true,
            style: { minWidth: '106px' },
            formatting: (v: any) => {
                return <div> {v === 'true' ? 'Yes' : 'No'} </div>;
            },
        },
        {
            id: 'po_no',
            name: 'PO #',
            prop: 'po_no',
            open: false,
            show: true,
            style: { minWidth: '106px' },
            formatting: (v: any) => <div>{v.toString()}</div>,
        },
        {
            id: 'divisionname',
            name: 'Product Group',
            prop: 'divisionname',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'gbm_name',
            name: 'Division',
            prop: 'gbm_name',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'sku',
            name: 'SKU',
            prop: 'sku',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '150px' },
        },
        {
            id: 'master_bl',
            name: 'BL #',
            prop: 'master_bl',
            open: false,
            show: true,
            style: { minWidth: '150px', maxWidth: '150px' },
        },
        {
            id: 'cntr_truckno',
            name: 'CNTR #',
            prop: 'cntr_truckno',
            open: false,
            show: true,
            style: { minWidth: '130px' },
        },
        {
            id: 'so',
            name: 'SO #',
            prop: 'so',
            open: false,
            show: true,
            style: { minWidth: '108px' },
        },
        {
            id: 'shiptoname',
            name: 'Ship To',
            prop: 'shiptoname',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'soldtoname',
            name: 'Sold To Name',
            prop: 'soldtoname',
            open: false,
            show: true,
            style: { minWidth: '260px', maxWidth: '260px' },
        },
        {
            id: 'departurectry',
            name: 'DEP',
            prop: 'departurectry',
            open: false,
            show: true,
            style: { minWidth: '108px' },
        },
        {
            id: 'departureport',
            name: 'Dep Port',
            prop: 'departureport',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: 'dischargingctry',
            name: 'DIS',
            prop: 'dischargingctry',
            open: false,
            show: true,
            style: { minWidth: '108px' },
        },
        {
            id: 'discharging__port',
            name: 'Dis Port',
            prop: 'discharging__port',
            open: false,
            show: true,
            style: { minWidth: '130px' },
        },
        {
            id: 'finalctry',
            name: 'DES',
            prop: 'finalctry',
            open: false,
            show: true,
            style: { minWidth: '108px' },
        },
        {
            id: 'finalport',
            name: 'Des Port',
            prop: 'finalport',
            open: false,
            show: true,
            style: { minWidth: '170px' },
        },
        {
            id: 'incoterms1',
            name: 'Inco Terms',
            prop: 'incoterms1',
            open: false,
            show: true,
            style: { minWidth: '100px' },
        },
        {
            id: 'shipping__carrier__grouping',
            name: 'Shipping',
            prop: 'shipping__carrier__grouping',
            open: false,
            show: true,
            style: { minWidth: '210px' },
        },
        {
            id: 'historicleadtime',
            name: 'Lead Time',
            prop: 'historicleadtime',
            open: false,
            show: true,
            cellType: 'pl.Int64',

            style: { minWidth: '100px', marginLeft: '1px', borderLeft: '1px solid #000' },
        },
        {
            id: 'forecastlt',
            name: 'Forecast LT',
            prop: 'forecastlt',
            open: false,
            show: false,
            cellType: 'pl.Int64',

            style: { minWidth: '100px' },
        },
        // {
        //     id: 'lt',
        //     name: 'Contract L/T',
        //     prop: 'lt',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Int64',
        //     style: { minWidth: '100px' },
        // },

        // {
        //     id: 'actuallt',
        //     name: 'Actual LT',
        //     prop: 'actuallt',
        //     open: false,
        //     show: true,
        //     cellType: 'pl.Int64',
        //     style: { minWidth: '100px' },
        // },

        {
            id: 'contract_lty23',
            name: 'LT Y23',
            prop: 'contract_lty23',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '100px' },
            formatting: (v: any) => {
                return `${v !== null ? parseFloat(v).toFixed(0) : ''}`;
            },
        },
        {
            id: 'gap',
            name: 'Gap',
            prop: 'gap',
            open: false,
            show: true,
            formatting: (v: any) => {
                return (
                    <div
                        style={{
                            color: v <= 0 ? '#137fc4' : '#a4282c',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: '2px',
                        }}
                    >
                        <strong>
                            {v !== null && typeof v === 'number'
                                ? v.toFixed(0)
                                : typeof v === 'string'
                                ? parseFloat(v).toFixed(0)
                                : v}
                        </strong>
                    </div>
                );
            },
            cellType: 'pl.Int64',
            style: { minWidth: '100px', marginRight: '1px', borderRight: '1px solid #000' },
        },
        {
            id: 'port_atddate',
            name: 'ATD',
            prop: 'port_atddate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px' },
        },
        {
            id: 'port_atd_itts_date',
            name: 'ATD ITTS',
            prop: 'port_atd_itts_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px' },
        },
        {
            id: 'atd_rpa_date',
            name: 'ATD RPA DATE',
            prop: 'atd_rpa_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px' },
        },

        {
            id: 'port_etadate',
            name: 'ETA',
            prop: 'port_etadate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px' },
        },
        {
            id: 'port_atadate',
            name: 'ATA',
            prop: 'port_atadate',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px' },
        },
        {
            id: 'ata_rpa_date',
            name: 'ATA RPA DATE',
            prop: 'ata_rpa_date',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px', borderRight: '1px solid #000' },
        },
        {
            id: 'booking_etd',
            name: 'Booking ETD',
            prop: 'booking_etd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px' },
        },
        {
            id: 'booking_eta_tp0331_01',
            name: 'Booking ETA',
            prop: 'booking_eta_tp0331_01',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px', maxWidth: '100px', borderRight: '1px solid #000' },
        },
        {
            id: 'notice_date_pre_alert',
            name: 'NOTICE DATE PRE ALERT',
            prop: 'notice_date_pre_alert',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '150px' },
        },
        {
            id: 'notice_date_delay',
            name: 'NOTICE DATE DELAY',
            prop: 'notice_date_delay',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px', maxWidth: '140px' },
        },
        {
            id: 'notice_date_arrival',
            name: 'NOTICE DATE ARRIVAL',
            prop: 'notice_date_arrival',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px', maxWidth: '140px' },
        },
        {
            id: 'send_pre_alert',
            name: 'Pre Alert',
            prop: 'send_pre_alert',
            key: 'send_pre_alert',
            open: false,
            show: true,
            style: { minWidth: '100px', marginLeft: '1px' },
            computed(row?: object) {
                let r: any = row;
                const send_pre_alert = getAlertFieldByType(r, 'Pre Alert Notice');
                // console.log("computed", send_pre_alert)
                return send_pre_alert;
            },
            formatting: (v: any) => (
                <>
                    {v!.includes('show') && (
                        <button className="btn btn-success" onClick={() => sendAlert(v)}>
                            <strong>Send </strong>
                        </button>
                    )}
                </>
            ),
        },
        {
            id: 'send_delay',
            name: 'Delay Notice',
            prop: 'send_delay',
            key: 'send_delay',
            open: false,
            show: true,
            style: { minWidth: '130px' },
            computed(row?: object) {
                let r: any = row;
                const send_delay = getAlertFieldByType(r, 'Delay Notice');
                return send_delay;
            },
            formatting: (v: any) => (
                <>
                    {v!.includes('show') && (
                        <button className="btn btn-success" onClick={() => sendAlert(v)}>
                            <strong>Send </strong>
                        </button>
                    )}
                </>
            ),
        },
        {
            id: 'send_arrival',
            name: 'Arrival Notice',
            prop: 'send_arrival',
            key: 'send_arrival',
            open: false,
            show: true,
            style: { minWidth: '110px', borderRight: '1px solid #000' },
            computed(row?: object) {
                let r: any = row;
                const send_arrival = getAlertFieldByType(r, 'Arrival Notice');
                return send_arrival;
            },
            formatting: (v: any) => (
                <>
                    {v!.includes('show') && (
                        <button className="btn btn-success" onClick={() => sendAlert(v)}>
                            <strong>Send </strong>
                        </button>
                    )}
                </>
            ),
        },
        {
            id: 'intransitdate',
            name: 'In Transit Date',
            prop: 'intransitdate',
            open: false,
            show: true,
            type: 'date_column',
            cellType: 'pl.Date',
            style: { minWidth: '120px' },
        },
        {
            id: 'port_1sttransit_crty',
            name: 'Port 1sttransit Crty',
            prop: 'port_1sttransit_crty',
            open: false,
            show: true,
            style: { minWidth: '130px' },
        },
        {
            id: 'port_1sttransit_port',
            name: '1sttransit Port',
            prop: 'port_1sttransit_port',
            open: false,
            show: true,
            style: { minWidth: '170px' },
        },
        {
            id: '1st_transit_dateata',
            name: '1st Transit Dateata',
            prop: '1st_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '130px' },
        },
        {
            id: '1st_transit_dateatd',
            name: '1st Transit Date Atd',
            prop: '1st_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '160px' },
        },
        {
            id: 'port_2ndtransit_crty',
            name: 'Port 2ndtransit Crty',
            prop: 'port_2ndtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: 'port_2ndtransit_port',
            name: '2nd Transit Port',
            prop: 'port_2ndtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '2nd_transit_dateata',
            name: '2nd Transit Date Ata',
            prop: '2nd_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
        {
            id: '2nd_transit_dateatd',
            name: '2nd Transit Date Atd',
            prop: '2nd_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '140px' },
        },
        {
            id: 'port_3rdtransit_crty',
            name: 'Port 3rdtransit Crty',
            prop: 'port_3rdtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '140px' },
        },
        {
            id: 'port_3rdtransit_port',
            name: '3rdtransit Port',
            prop: 'port_3rdtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '3rd_transit_dateata',
            name: '3rd Transit Date Ata',
            prop: '3rd_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '160px' },
        },
        {
            id: '3rd_transit_dateatd',
            name: '3rd_transit Date Atd',
            prop: '3rd_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '160px' },
        },
        {
            id: 'port_4thtransit_crty',
            name: 'Port 4thtransit Crty',
            prop: 'port_4thtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: 'port_4thtransit_port',
            name: '4thtransit Port',
            prop: 'port_4thtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '4th_transit_dateata',
            name: '4th Transit Dateata',
            prop: '4th_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '160px' },
        },
        {
            id: '4th_transit_dateatd',
            name: '4th Transit Date Atd',
            prop: '4th_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '160px' },
        },
    ];

    const reduceColumns = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true) {
                result[column.id] = column.name;
            }
            return result;
        }, {});
        return res;
    };

    const reduceColumnTypes = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true && column.cellType !== undefined && column.cellType !== null) {
                result[column.id] = column.cellType;
            }
            return result;
        }, {});
        return res;
    };
    const exportExcel = async (columns: any) => {
        setDownLoader(true);
        const col = reduceColumns(columns);
        const column_types = reduceColumnTypes(columns);
        const body: any = queries_glp.mainTable.query;
        delete body.data[0].offset;
        delete body.data[0].limit;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp.mainTable.groupsBy,
                        name: queries_glp.mainTable.table,
                    },
                    body: queries_glp.mainTable.query,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = { ...queries };
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
        setDownLoader(false);
    };
    const updateData = (newData: any) => {
        setMainTableData([]);
        setMainTableData(newData);
    };

    const searchData = (type: any) => {
        setApplySearch(true);
        type = type.replace(/\s+/g, ' ').trim();
        setSearchText(type);
        if (type === '') {
            clearValues();
            setQueries({ ...queries, searchGlp: null });
        } else if (type.length > 2) {
            clearValues();
            setQueries({ ...queries, searchGlp: type.toUpperCase() });
        }
    };
    const sortData = (type: any) => {
        setApplySearch(true);
        setPage(1);
        if (type.name === 'Descending') {
            setSortBy(`${type.prop},desc`);
            setQueries({
                ...queries,
                page: 1,
                size: size_page * 1,
                sort_by: `${type.prop},desc`,
            });
        } else {
            setSortBy(`${type.prop}`);
            setQueries({ ...queries, page: 1, size: size_page * 1, sort_by: `${type.prop}` });
        }
        //setPage(1);
    };
    useEffect(() => {
        if (totalDatas !== -1 && mainTableData.length > 0) {
            if (mainTableData?.length < totalDatas) {
                setMoreItems(true);
            } else {
                setMoreItems(false);
            }
        }
    }, [totalDatas, mainTableData]);

    const fetchData = async (isScroll: boolean = false) => {
        // avoid multiple call to the fetchData
        if (loadingMainData) {
            return;
        }
        const updatedQueries = {
            ...queries,
            page: page + 1,
            size: size_page,
            sort_by: sortBy !== '' ? sortBy : null,
        };
        setQueries(updatedQueries);
        setIsLoadingDataScroll(isScroll);
        setLoadingMainData(true); // Establecer isLoadingData a true antes de la solicitud

        try {
            // make and run request
            const response = await queryV1(
                queries_glp.mainTable.table,
                queries_glp.mainTable.groupsBy,
                queries_glp.mainTable.query,
                updatedQueries,
                'athena'
            );
            // update data and state variables
            const newDataToAdd = response.data?.map((row: any, index: number) => {
                return { ...row, index: index + page * size_page };
            });
            const newData: any[] = [...mainTableData, ...newDataToAdd];
            setMainTableData(isScroll ? newData : response.data);
            if (newData.length >= totalDatas) {
                setMoreItems(false);
            } else {
                setPage((prev) => prev + 1);
            }
            setExecuteApply(false);
            setApplySearch(false);

            // Reset loading states
            setLoadingMainData(false);
            setIsLoadingDataScroll(false);
        } catch (error) {
            // Reset loading states
            setIsLoadingDataScroll(false);
            setLoadingMainData(false);
        }
    };

    const leadTimeShippingCompanyDataMemoized = useMemo(() => {
        return (
            <div className={styles['chartShipping']}>
                {!loadLeadTimeShippingCompanyData ? (
                    <HorizontalBarEChart
                        className={'chartShipping'}
                        title="L/T Per Shipping Company"
                        data_chart={convertDataToChart(leadTimeShippingCompanyData)}
                    />
                ) : (
                    <Spinner size="sm" />
                )}
            </div>
        );
    }, [loadLeadTimeShippingCompanyData, leadTimeShippingCompanyData]);

    const data_chart_changes_per_discharging_port = useMemo(() => {
        return (
            <div className={styles['chartF']}>
                {!loadLeadTimeDischargingPortData ? (
                    <HorizontalBarEChart
                        className={'chartF'}
                        title="L/T Per Discharging Port (AVG Days)"
                        data_chart={convertDataToChart(leadTimeDischargingPortData)}
                    />
                ) : (
                    <Spinner size="sm" />
                )}
            </div>
        );
    }, [loadLeadTimeDischargingPortData, leadTimeDischargingPortData]);

    const leadTimeTransportModeDataMemoized = useMemo(() => {
        return (
            <div className={styles['chartShipping']}>
                {!loadLeadTimeShippingCompanyData ? (
                    <HorizontalBarEChart
                        className={'chartShipping'}
                        title="L/T Per Shipping Company"
                        data_chart={convertDataToChart(leadTimeShippingCompanyData)}
                    />
                ) : (
                    <Spinner size="sm" />
                )}
            </div>
        );
    }, [loadLeadTimeShippingCompanyData, leadTimeShippingCompanyData]);

    const leadTimeCountryDataMemoized = useMemo(() => {
        return (
            <div className={styles['chartC'] + ' ' + styles.textReportTableDT}>
                {!loadLeadTimeCountryData ? (
                    <PieEChart
                        className={'chartC'}
                        title="L/T Per Continent (AVG Days)"
                        name="AVG L/T"
                        data_chart={convertDataToChart(leadTimeCountryData)}
                    />
                ) : (
                    <Spinner size="sm" />
                )}
            </div>
        );
    }, [loadLeadTimeCountryData, leadTimeCountryData]);

    const MemoizedTable = useMemo(() => {
        return (
            <div className={styles['mainTable']}>
                <Table
                    isSearch={true}
                    searchFunction={searchData}
                    searchText={searchText}
                    sortFunction={sortData}
                    columnsButton={true}
                    resetButton={true}
                    columns={mainColumns}
                    data={mainTableData}
                    count={totalDatas}
                    exportLoader={downLoader}
                    fetchData={fetchData}
                    title=""
                    exportData={exportExcel}
                    haveMoreItems={moreItems}
                    updateData={updateData}
                    isLoadingData={loadingMainData}
                    isLoadingDataScroll={isLoadingDataScroll}
                    allColumnsStyle=" max-w-[200px] "
                    activeCompactMode={true}
                    onReset={resetFilters}
                    filterByArrival={false}
                />
            </div>
        );
    }, [
        searchData,
        searchText,
        sortData,
        mainColumns,
        mainTableData,
        totalDatas,
        downLoader,
        fetchData,
        exportExcel,
        moreItems,
        updateData,
        loadingMainData,
        isLoadingDataScroll,
        resetFilters,
    ]);

    const getComponents = () => {
        return (
            <div className={styles.root}>
                <div className={styles.upper}>
                    <div className={styles.headerReport}>
                        <span className={styles.descriptionCountry}>
                            {finalctry !== undefined ? finalctry?.toUpperCase() : 'SELA'}
                        </span>
                        <span className={styles.tittleReport}>
                            Logistic Leadtime Tracking System: Logistics Version
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '7px',
                        }}
                    >
                        {closedFilters && (
                            <div className={styles.filterButton}>
                                <FilterButton
                                    className="hidden"
                                    close={closeFilter}
                                    top={154}
                                    regularFormat={true}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'end',
                            }}
                        >
                            <div className={styles.textReport}>
                                <span>
                                    Period: {formatterDate(priorDate)} to {formatterDate(today)}
                                </span>
                            </div>
                            <div className={styles.textUpdateReport}>
                                <span>
                                    Update by GLP Outbound Tracking View at {formatterDate(today)}
                                    {/* <span className={styles.refresh}>
                                                <Button
                                                    click={() => { }}
                                                    styleProp=" rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray  gap-1 items-center h-100 min-w-[100px]"
                                                >
                                                    <ArrowPathIcon className={'h-6 w-6 '} /><span className={styles.buttonLabel}> Refresh</span>
                                                </Button>
                                            </span> */}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.intermediate}>
                    <div className={styles.section1}>
                        <div>{leadTimeShippingCompanyDataMemoized}</div>
                    </div>
                    <div className={styles.section2}>{data_chart_changes_per_discharging_port}</div>
                    <div className={styles.section3}>
                        <div className={styles['chartTransport']}>
                            {leadTimeTransportModeDataMemoized}
                        </div>
                    </div>
                    <div className={styles.section4}>{leadTimeCountryDataMemoized}</div>
                    <div className={styles.section5}>
                        <div className={styles['chartMap']}>
                            <PortMap
                                className="glpMap"
                                title="L/T Per Region"
                                /* data_chart={{
                                    data: [],
                                    geoCoorMap: convertDataToMap(leadTimePortData),
                                }} */
                                lead_time={leadTimePortData}
                                center_map={{ lat: 15.0, lng: -73.0 }}
                                zoom={3.1}
                            />
                        </div>
                    </div>
                </div>
                <div>{MemoizedTable}</div>
            </div>
        );
    };

    return (
        <div className={classNames(styles.root) + ' flex h-[98vh] w-screen ' + name}>
            {!closedFilters && (
                <FilterCard
                    close={closeFilter}
                    applyFilters={applyFilters}
                    columns={mainColumns}
                    setUniques={setUniques}
                    filters={filtersString}
                    file={'glp-shipmentoptimized'}
                    uniques={uniques}
                    by="athena"
                    onReset={clearValues}
                    setExecuteApply={setExecuteApply}
                    setApplySearch={setApplySearch}
                    setCustomFilters={setCustomFilters}
                    customFilters={customFilters}
                    isColapsed={true}
                />
            )}
            <div className={closedFilters ? 'w-[100%]' : 'w-[80%]'}>
                {dataLoaded && getComponents()}
            </div>
        </div>
    );
};
